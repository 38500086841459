<template>
  <div class="container" :style="{width: width,height: height,boxSizing: 'border-box'}">
    <div class="avatar">
      <el-image  
			@click="go_pay_img()"
          style="width: 100%;height: 195px;;"
          :src="info.pic"
          fit="cover"
      />
    </div>
    <div class="info">
      <div class="info_name">{{info.name}}</div>
      <div class="info_intro">
        <p  @click="handleClick()" >专业：{{info.major}}</p>
        <p  @click="handleClick()" >学历：{{info.education}}</p>
        <p  @click="handleClick()" >年龄：{{info.age}}</p>
        <p  @click="handleClick()" >职称：{{info.competent}}</p> 
			
			
      </div>
      <div class="info_more">
        <!-- <el-button @click="handleClick" >查看详情</el-button> -->
		<el-button @click="handleClick" >查看详情</el-button>
      </div>
    </div>
	<pay-img-dialog ref="PayImg"></pay-img-dialog>
  </div>
</template>
 
<script>
	
	import PayImg from "../views/children/service/components/PayImgDialog.vue";
  export default {
    name: "DocCard",
    components: {PayImg},
    props: {
      info: {
        type: Object,
        default: function () {
          return {
            name: '弘淑',
            major: '心脑专业',
            education: '医学博士',
            age: '25岁',
            competent: '国家十大青年代表，2020济南之光',
            pic: require('@/assets/avatar/弘淑.jpg')
          }
        }
      },
      width: {
        type: String,
        default: '180px'
      },
      height: {
        type: String,
        default: '423px'
      }
    },

    methods:{
		
	go_pay_img(){  
			
			  this.$emit("goPay");
	},
	
      /**
       * 当点击查看详情时的操作按钮，执行回调
       */
      handleClick(){
        this.$emit("btn-click")
      }
    }
  }
</script>

<style scoped>
  .container{
    padding: 18px 14px;
    border-radius: 2px;
    background-color: #fff;
    margin-bottom: 20px;
  }

  .container:hover{
     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
   }
  .container.active{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .info{
    font-size: 12px;
    line-height: 24px;
  }
  .info_name{
    font-size: 18px;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #33D0DC;
  }
  .info_intro{
    margin-bottom: 10px;
    letter-spacing: 0;
  }
  .info_more .el-button{
    width: 90%;
    margin-left: 5%;
  }
</style>
